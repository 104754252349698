import axios from "axios";
import "draft-js/dist/Draft.css"; // Import Draft.js CSS
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { trackDuration, trackLanding } from "./Components/Apis/AnalyticApi";
import { verifyUser } from "./Components/Apis/AuthApi";
import PhysicalProducts from "./Components/PhysicalProducts/PhysicalProducts";
import { changeStatus } from "./Components/Redux/VerifyUserStatus";
import { addProductNames } from "./Components/Redux/filterList";
import { changeColor } from "./Components/Redux/theme";
import StoreLocation from "./Components/StoreLocation/StoreLocation";
import Loader from "./Components/Utils/Loader";
import { random } from "./Components/Utils/randomId";

// home header footer
const Footer = lazy(() => import("./Components/Home/Footer/Footer"));
const Headers = lazy(() => import("./Components/Home/Headers"));

// Auth
const Home = lazy(() => import("./Components/Home/Home/Home"));
const Reset = lazy(() => import("./Components/Auth/Reset"));

// consumer-products
const ProductsHomePage = lazy(() =>
  import("./Components/PhysicalProducts/ProductsHomePage")
);

//
const Logout = lazy(() => import("./Components/Dashboard/Home/Logout"));
const DisplayMode = lazy(() =>
  import("./Components/Home/DisplayMode/DisplayMode")
);
// Account
const Account = lazy(() => import("./Components/Dashboard/Account/Account"));
const MyAccount = lazy(() => import("./Components/myAccount/MyAccount"));
const UserContent = lazy(() => import("./Components/CompanyInfo/UserContent"));

// business
const BusinessAccount = lazy(() =>
  import("./Components/Business/Account/BusinessAccount")
);
const Analytics = lazy(() =>
  import("./Components/Business/Analytics/Analytics")
);
const Business = lazy(() => import("./Components/Business/Business"));
const BusinessHome = lazy(() =>
  import("./Components/Business/Home/BusinessHome")
);
const Products = lazy(() => import("./Components/Business/Products/Products"));
const UserList = lazy(() => import("./Components/Business/UserList/UserList"));
const Access = lazy(() => import("./Components/Business/Access/Access"));
const ContentHome = lazy(() =>
  import("./Components/Business/ContentPage/ContentHome")
);
const LogoutBusiness = lazy(() =>
  import("./Components/Business/Home/LogoutBusiness")
);

function App() {
  const token = localStorage.getItem("token");
  const [startTime, setStartTime] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const themeColor = useSelector((state) => state.theme.value);
  const loader = useSelector((state) => state.loader.value);
  const headers = {
    authorization: `Bearer ${token}`,
  };

  const containerRef = useRef(null);
  
  useEffect(() => {
    if (containerRef?.current) {
      containerRef?.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("Mode")) {
      dispatch(changeColor(localStorage.getItem("Mode")));
    }
    const fetchVerificationStatus = async () => {
      const isUserVerified = await verifyUser();
      if (isUserVerified) {
        dispatch(changeStatus(true));
      }
    };
    fetchVerificationStatus();
    getOnlyProductsName();
  }, []);

  const getOnlyProductsName = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/get-only-product-names`
    );
    if (data.success) {
      dispatch(addProductNames(data.result));
    }
  };

  useEffect(() => {
    if (token) {
      getUserInfo();
    } else if (
      !sessionStorage.getItem("visitorId") &&
      window.location.hostname === "www.ganeshswami.in" &&
      window.location.pathname !== "/business"
    ) {
      sessionStorage.setItem("visitorId", random());
      const data = {
        visitorId: sessionStorage.getItem("visitorId"),
        name: location.pathname,
      };
      trackLanding(data);
      setStartTime(new Date());
    }
  }, []);

  const getUserInfo = async () => {
    axios
      .get(`${process.env.REACT_APP_API}/get-user-info`, { headers })
      .then((res) => {
        if (res.data.success) {
          dispatch(changeColor(res.data.mode));
        } else if (res.data.msg === "clear ls") {
          localStorage.clear();
        }
      });
  };

  document.addEventListener("visibilitychange", function logAnalyticsData() {
    if (
      document.visibilityState === "hidden" &&
      window.location.hostname === "www.ganeshswami.in" &&
      window.location.pathname !== "/business"
    ) {
      const data = {
        s: startTime,
        e: new Date(),
        v: sessionStorage.getItem("visitorId"),
      };
      trackDuration(data);
    }
  });

  return (
    <div
      className="App"
      ref={containerRef}
      tabIndex="-1"
      // style={{ outline: "none" }}
      style={{
        height: "100vh",
        backgroundColor: themeColor === "Dark" ? "#202020" : "#fff",
        overflow: "auto",
        outline: "none"
      }}
    >
      {loader && <Loader />}
      {/* Home */}
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        <Routes>
          <Route exact path="/" element={[<Headers />, <ProductsHomePage />]} />

          <Route
            exact
            path="/auth"
            element={[<Headers />, <MyAccount />, <Home />, <Footer />]}
          />

          {/* account after login */}
          <Route
            exact
            path="/home"
            element={[<Headers />, <ProductsHomePage />]}
          />
          <Route
            exact
            path="/:id"
            element={[<Headers />, <PhysicalProducts />]}
          />
          <Route
            exact
            path="/account"
            element={[<Headers />, <MyAccount />, <Account />]}
          />
          {/* common */}
          <Route
            exact
            path="/office-location"
            element={[<Headers />, <MyAccount />, <StoreLocation />]}
          />
          <Route
            exact
            path="/office-location"
            element={[<Headers />, <StoreLocation />]}
          />

          <Route
            exact
            path="/about-us"
            element={[<Headers />, <MyAccount />, <UserContent />]}
          />
          <Route
            exact
            path="/blogs"
            element={[<Headers />, <MyAccount />, <UserContent />]}
          />
          <Route
            exact
            path="/faq"
            element={[<Headers />, <MyAccount />, <UserContent />]}
          />
          <Route exact path="/logout" element={[<Headers />, <Logout />]} />

          <Route
            exact
            path="/display-mode"
            element={[<Headers />, <MyAccount />, <DisplayMode />]}
          />

          {/* reset password */}
          <Route exact path="/:id-reset" element={<Reset />} />
          {/* Business */}
          <Route exact path="/business" element={<Business />} />
          {/* home */}
          <Route exact path="/business/home" element={<BusinessHome />} />
          {/* analytics */}
          <Route
            exact
            path="/business/analytics"
            element={[<BusinessHome />, <Analytics />]}
          />

          {/* account */}
          <Route
            exact
            path="/business/account"
            element={[<BusinessHome />, <BusinessAccount />]}
          />
          {/* products */}
          <Route
            exact
            path="/business/products"
            element={[<BusinessHome />, <Products />]}
          />

          {/* userlist */}
          <Route
            exact
            path="/business/user"
            element={[<BusinessHome />, <UserList />]}
          />
          {/* access */}
          <Route
            exact
            path="/business/employees"
            element={[<BusinessHome />, <Access />]}
          />

          {/* Content */}
          <Route
            exact
            path="/business/content"
            element={[<BusinessHome />, <ContentHome />]}
          />

          {/* logout */}
          <Route
            exact
            path="/business/logout"
            element={[<BusinessHome />, <LogoutBusiness />]}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
